@media print {
    header, footer, .left-side, .right-side, .DynamicBreadcrumbPortlet, .social-elements, .footer {
        display: none !important;
    }
    .left-side {
        margin-top: 0 !important;
    }
    .article-body {
        margin-top: 1.5em !important;
    }
    h1 {
        color: #000 !important;
        font-size: 15pt !important;
        padding-top: 0 !important;
        page-break-after: avoid;
    }
    h2, h3 {
        color: #000 !important;
        font-size: 13pt !important;
        page-break-after: avoid;
    }
    p, div, span {
        color: #000 !important;
        font-size: 10pt !important;
        padding: 0 !important;
        line-height: 1.2em !important;
        border-color: #555 !important;
    }
    a {
        color: #555 !important;
        padding: 0 !important;
    }
    a[href^="http://"]:after {
        content: " (" attr(href) ")" !important;
        font-size: 80% !important;
        color: #000 !important;
    }
    .left-side-column {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    table {
        color: #000 !important;
        font-size: 10pt !important;
        margin: 0 !important;
        border-collapse: collapse !important;
        background-color: #888 !important;
    }
    td, th {
        border: 1px solid #000 !important;
        padding: 5px !important;
    }
    li {
        font-size: 10pt !important;
        line-height: 1.2 !important;
        color: #000 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .kapcsolodo {
        font-size: 10pt !important;
    }
    .kapcsolodo a {
        font-size: inherit !important;
    }
    .kapcsolodo img {
        display: none !important;
        padding: 0 !important;
    }
    img {
	    filter: grayscale(100%);
    }
    @page {
        margin: 1.5cm 1cm;
    }
}
